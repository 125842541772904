<template>
  <div class="jifen bgcolor">
      <Head :title="$t('score.my_score')" :show="true" :bg="true" />
      <div class="jifen-main" >
          <div class="jifen-top">
              <div class="jifen-top-box" v-if="flag">
                <div class="jifen-top-box-left">
                    <p>{{$t('score.my_score')}}</p>
                    <strong>{{pvTotal | number_format}}</strong>
                </div>
                <div class="jifen-top-box-right">
                    <p @click="show=true">
                        <span>{{choosetxt}}</span>
                        <img  class="img-arrow" src="../../../assets/imgs/arrow-down.png" alt="">
                    </p>
                </div>
              </div>
          </div>
          <div class="jifen-box">
              <div class="jifen-list">
                <MyList :listadd="listadd" ref="mylist" >
                    <div class="jifen-item" v-for="(l,i) in list" :key="i" >
                        <div class="jifen-item-left">
                            <p class="jifen-cate">{{l.remark}}</p>
                            <p class="jifen-log"> {{l.send_time}}</p>
                        </div>
                        <div class="jifen-item-right">
                            {{l.is_send==2?'+':'-'}} {{l.amount}}
                        </div>
                    </div>
                </MyList>
              </div>
          </div>
      </div>
      <!-- PV选择  -->
     <van-popup class="login-popup taione-one" v-model="show" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('score.choose_type')}}</p>
            </div>
            <div class="btn-list">
                <van-button @click="changeType(-1,$t('common.all'),'-1','-1')"  class="btn-list-item " :class="current==-1?'active':''">{{$t('score.all')}}</van-button>
                <van-button @click="changeType(0,$t('home.qudao'),'87','88')"  class="btn-list-item"  :class="current==0?'active':''">{{$t('score.length')}}</van-button>
                <van-button @click="changeType(1,$t('home.recharge'),'0','0')"   class="btn-list-item"  :class="current==1?'active':''">{{$t('score.exchange')}}</van-button>
                <van-button @click="changeType(2,$t('home.bet'),'89','92')"   class="btn-list-item"  :class="current==2?'active':''">{{$t('score.auction')}}</van-button>
                <van-button @click="changeType(3,$t('home.showdan'),'93','94')"  class="btn-list-item"  :class="current==3?'active':''">{{$t('score.sheet')}}</van-button>
                <van-button @click="changeType(4,$t('home.qian'),'81','81')"  class="btn-list-item"  :class="current==4?'active':''">{{$t('mine.qiandao')}}</van-button>
            </div>
            <van-button class="cancel-btn sure-btn" @click="show=false">{{$t('login.cancel')}}</van-button>
        </div>
    </van-popup>
  </div>
</template>


<script>
import {myinfo} from "@/common"
export default {
    mixins:[myinfo],
    data(){
        return {
            list:[],
            total:0,
            flag:false,
            show:false,
            choosetxt:this.$t('common.all'),
            current:-1,
            page:1,
            pageSize:50,
            all:0,
            pvTotal:0
        }
    },
    watch:{
        user:{
            deep:true,
            handler(v){
                this.pvTotal  = v.walletlist.find(item=>item.current_id=='1033').usable_balance
            }
        }
    },
    methods:{
        refresh(){

        },
       changeType(current,type,min,max){
           this.current = current;
           this.choosetxt = type;
            //   过滤代码
           this.show = false;
           if(max=='-1'&&min=='-1'){
               this.list = [...this.oldlist]
           }else if(max=="0"&&min=="0"){
               this.list = [];
           }else{
               this.list = this.oldlist.filter(item=>{
                   return item.rechargeType >= min * 1 && item.rechargeType <= max * 1;
               })
           }
       },
       listadd(callback){
        //    this.list = this.list.concat(this.list)
            if(this.page<Math.ceil(this.all/this.pageSize)){
                this.page++;
                
                this.$ajax.pvlist({
                    page:this.page,
                    pageSize:this.pageSize,
                })
                .then(res=>{
                    if(res.code==200){
                        this.list = this.list.concat(res.data.list)
                        this.oldlist = this.oldlist.concat(res.data.list)
                        this.flag = true;
                        this.all = res.data.total;
                        callback()
                        res.data.list.forEach(item=>{
                            if(item.is_send==2){
                                this.total+=item.amount*1;
                            }else {
                                this.total-=item.amount*1;
                            }
                        })
                    }
                })
            }else{
                this.$refs.mylist.finished = true;  // 加载结束 
            }
            
       },
       getData(params){
           // is_send 1 减分  2 加分 
        this.$ajax.pvlist(params)
        .then(res=>{
            if(res.code==200){
                this.list = res.data.list;
                this.oldlist =res.data.list;
                this.flag = true;
                this.all = res.data.total;
            }
        })
       }
    },
    mounted(){
        this.getData({
            page:this.page,
            pageSize:this.pageSize,
        })

        // 计算我的pv 
        if(this.user){
            this.pvTotal  = this.user.walletlist.find(item=>item.current_id=='1033').usable_balance
        }
    }
}
</script>
